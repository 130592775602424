<template>
  <div>
    <v-container class="my-0 my-md-16 py-0  px-0 px-md-3 pb-md-16">
      <v-row class="no-gutters">
        <v-col
          xl="8"
          offset-xl="2"
          md="10"
          offset-md="1"
          sm="12"
          offset-sm="0"
          class="py-0"
        >
          <v-data-table
            :headers="headers"
            :items="kategoriler"
            :search="search"
            :disable-sort="$vuetify.breakpoint.smAndDown ? true : false"
            :class="
              $vuetify.breakpoint.smAndDown ? 'elevation-0' : 'elevation-24'
            "
            style="border-radius:6px"
          >
            <template v-slot:top>
              <v-toolbar
                flat
                color="primary"
                height="120px"
                dark
                :class="
                  $vuetify.breakpoint.smAndDown
                    ? 'rounded-t-0'
                    : 'rounded-t-lg pl-3'
                "
              >
                <v-toolbar-title class="d-flex align-center">
                  <v-icon large>
                    mdil-sitemap
                  </v-icon>
                  <span class="pl-6 text-h4">{{ $t("kategoriler") }}</span>
                </v-toolbar-title>
                <v-spacer />
                <div
                  v-if="$vuetify.breakpoint.smAndDown ? false : true"
                  class="d-flex py-6 align-center"
                >
                  <v-text-field
                    v-model="search"
                    class="pr-6"
                    append-icon="mdi-magnify"
                    :label="$t('ara')"
                    hide-details
                  />
                </div>
                <v-btn
                  color="accent"
                  dark
                  depressed
                  class="mb-2"
                  @click="dialog = true"
                >
                  {{ $t("yeniEkle") }}
                </v-btn>
              </v-toolbar>
            </template>
            <template v-slot:header.ad="{ item }">
              {{ $t("ad") }}
            </template>
            <template v-slot:header.durum="{ item }">
              {{ $t("durum") }}
            </template>

            <template v-slot:item.durum="{ item }">
              <v-icon v-if="item.durum" color="accent" medium>
                mdi-check-bold
              </v-icon>
              <v-icon v-else color="primary" medium>
                mdil-cancel
              </v-icon>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                v-if="item.file"
                small
                class="mr-2"
                @click="editItemFile(item)"
              >
                mdi-image
              </v-icon>
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <div>
      <v-dialog
        v-model="dialog"
        persistent
        :fullscreen="$vuetify.breakpoint.smAndDown ? true : false"
        scrollable
        max-width="500px"
        transition="dialog-bottom-transition"
      >
        <v-form ref="form" v-model="valid" :disabled="disabled" lazy-validation>
          <v-card :disabled="bekle" :loading="bekle">
            <v-card-title class="py-3 py-md-6">
              <div class="text-center text-h4  text-md-h3 font-weight-medium">
                {{ formTitle }}
              </div>
              <v-spacer />
              <v-btn
                x-small
                fab
                elevation="0"
                color="grey lighten-2"
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-divider />

            <v-card-text class="px-2 py-0" style="max-height: 100%;">
              <div id="scrollItem" class="scroll pa-3">
                <v-text-field
                  v-model="editedItem.ad"
                  outlined
                  rounded
                  hide-details
                  class="mb-6 mb-md-9"
                  :label="$t('ad')"
                  :counter="30"
                  :rules="nameRules"
                  required
                />

                <v-textarea
                  v-model="editedItem.aciklama"
                  outlined
                  rounded
                  hide-details
                  rows="2"
                  auto-grow=""
                  class="mb-6 mb-md-9"
                  :label="$t('aciklama')"
                />

                <v-file-input
                  v-if="typeof editedItem.file != 'string'"
                  v-model="editedItem.file"
                  :label="$t('gorsel')"
                  outlined
                  rounded
                  hide-details
                  append-icon="mdi-camera"
                  prepend-icon=""
                  accept="image/png, image/jpeg, image/jpg"
                  class="mb-6 mb-md-9"
                  :rules="fileRules"
                />

                <v-text-field
                  v-model.number="editedItem.sira"
                  outlined
                  rounded
                  hide-details
                  class="mb-6 mb-md-9"
                  :label="$t('sira')"
                />

                <v-switch
                  v-model="editedItem.show"
                  color="primary"
                  inset
                  :label="
                    `${
                      editedItem.show
                        ? $t('acikGosterim')
                        : $t('kapaliGosterim')
                    }`
                  "
                />

                <v-switch
                  v-model="editedItem.durum"
                  color="primary"
                  inset
                  :label="`${editedItem.durum ? $t('onayli') : $t('onaysiz')}`"
                />
              </div>
            </v-card-text>
            <v-divider />
            <v-card-actions class=" px-3 primary rounded-0">
              <div class="px-3" style="width:100%">
                <v-btn
                  block
                  :loading="bekle"
                  :large="$vuetify.breakpoint.smAndDown ? false : true"
                  depressed=""
                  class="rounded-pill text-capitalize text-body-1"
                  color="primary"
                  @click="save()"
                >
                  {{ formAction }}
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog v-model="dialogFile" width="600">
        <template>
          <v-container>
            <v-row justify="space-around">
              <v-card>
                <v-img height="400px" :src="editedItem.file">
                  <v-app-bar flat color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="h3 white--text pl-0">
                      {{ editedItem.ad }}
                    </v-toolbar-title>
                    <v-spacer />
                    <v-btn color="accent" @click="deleteItemFile()">
                      <v-icon left>
                        mdi-close
                      </v-icon>
                      {{ $t("sil") }}
                    </v-btn>
                  </v-app-bar>
                </v-img>
              </v-card>
            </v-row>
          </v-container>
        </template>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import Compressor from "compressorjs";
import { veri, depo } from "./../db";
import draggable from "vuedraggable";
var _ = require("lodash");
export default {
  name: "KategorilerView",
  components: {
    draggable
  },
  data: () => ({
    dialog: false,
    dialogFile: false,
    dialogDelete: false,
    search: "",

    kategoriler: [],
    editedIndex: -1,
    editedItem: {
      ad: "",
      aciklama: "",
      file: null,
      durum: true,
      show: true,
      sira: 0
    },
    defaultItem: {
      ad: "",
      aciklama: "",
      file: null,
      durum: true,
      show: true,
      sira: 0
    },
    disabled: false,
    bekle: false,
    valid: true,
    nameRules: [v => !!v || "Name is required"],
    fileRules: [
      v => !v || v.size < 10000000 || "Bild size should be less than 10 MB!"
    ],
    aciklamaRules: [
      v => v.length <= 30 || "Name must be less than 30 characters"
    ]
  }),
  firebase: {
    kategoriler: veri.ref("kategoriler").orderByChild("sira")
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t("yeniEkle") : this.$t("duzenle");
    },
    formAction() {
      return this.editedIndex === -1 ? this.$t("olustur") : this.$t("guncelle");
    },
    headers() {
      return [
        {
          text: this.$t("ad"),
          value: "ad"
        },

        {
          text: this.$t("durum"),
          align: "right",
          sortable: false,
          value: "durum"
        },
        {
          text: "",
          value: "actions",
          align: "right",
          filterable: false
        }
      ];
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  methods: {
    editItem(item) {
      if (typeof item.file === "undefined") {
        item.file = null;
      }
      this.editedIndex = this.kategoriler.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    editItemFile(item) {
      this.editedIndex = this.kategoriler.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogFile = true;
    },
    deleteItemFile() {
      confirm(this.$t("silOnay")) && this.deleteItemFileConfirm();
    },
    deleteItemFileConfirm() {
      var fileUrl = this.kategoriler[this.editedIndex].file;
      var fileRef = depo.refFromURL(fileUrl);
      fileRef.delete().then(() => {
        veri
          .ref(
            "kategoriler/" +
              this.kategoriler[this.editedIndex][".key"] +
              "/file"
          )
          .set(null)
          .then(() => {
            this.dialogFile = false;
            this.close();
          });
      });
    },
    deleteItem(item) {
      confirm(this.$t("silOnay")) && this.deleteItemConfirm(item);
    },
    deleteItemConfirm(item) {
      if (item.file) {
        var fileRef = depo.refFromURL(item.file);
        fileRef.delete().then(() => {
          veri.ref("kategoriler/" + item[".key"]).remove();
        });
      } else {
        veri.ref("kategoriler/" + item[".key"]).remove();
      }
    },
    close() {
      this.reset();
      this.bekle = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    save() {
      if (this.$refs.form.validate()) {
        this.bekle = true;
        if (this.editedIndex > -1) {
          if (_.isObject(this.editedItem.file)) {
            const file = this.editedItem.file;

            if (!file) {
              return;
            }
            var self = this;
            new Compressor(file, {
              quality: 0.6,
              minWidth: 800,
              height: 600,

              // The compression process is asynchronous,
              // which means you have to access the `result` in the `success` hook function.
              success(result) {
                var uploadTask = depo
                  .ref()
                  .child("images/" + Date.now() + "_" + result.name)
                  .put(result);
                uploadTask.on(
                  "state_changed",
                  sp => {},
                  null,
                  () => {
                    uploadTask.snapshot.ref
                      .getDownloadURL()
                      .then(downloadURL => {
                        self.editedItem.file = downloadURL;
                        veri
                          .ref(
                            "kategoriler/" +
                              self.kategoriler[self.editedIndex][".key"]
                          )
                          .set(self.editedItem)
                          .then(self.close());
                      });
                  }
                );
              },
              error(err) {
                this.close();
              }
            });
          } else {
            veri
              .ref("kategoriler/" + this.kategoriler[this.editedIndex][".key"])
              .set(this.editedItem)
              .then(this.close());
          }
        } else {
          if (this.editedItem.file) {
            const file = this.editedItem.file;

            if (!file) {
              return;
            }
            var self = this;
            new Compressor(file, {
              quality: 0.6,
              minWidth: 800,
              height: 600,

              // The compression process is asynchronous,
              // which means you have to access the `result` in the `success` hook function.
              success(result) {
                var uploadTask = depo
                  .ref()
                  .child("images/" + Date.now() + "_" + result.name)
                  .put(result);
                uploadTask.on(
                  "state_changed",
                  sp => {},
                  null,
                  () => {
                    uploadTask.snapshot.ref
                      .getDownloadURL()
                      .then(downloadURL => {
                        self.editedItem.file = downloadURL;
                        veri
                          .ref("kategoriler")
                          .push(self.editedItem)
                          .then(self.close());
                      });
                  }
                );
              }
            });
          } else {
            veri
              .ref("kategoriler")
              .push(this.editedItem)
              .then(this.close());
          }
        }
      }
    }
  }
};
</script>
<style lang="sass">
// .v-progress-linear .v-progress-linear__indeterminate .long, .v-progress-linear .v-progress-linear__indeterminate .short
//   background-color: #fb2db3 !important
//
// .v-card__text
//   height: 100%
//   overflow-y: auto
//   overflow-x: hidden !important
//   &::-webkit-scrollbar
//     width: 5px
//   &::-webkit-scrollbar-thumb
//     background: #fb2db3
//     border-radius: 20px
</style>
